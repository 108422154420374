import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { Membership, Person, PostOffice } from 'types/sp-api';
import * as Yup from 'yup';
import PersonsService from 'services/personsService';
import LoadButton from 'components/common/LoadButton';
import FormFieldText from 'components/common/FormFieldText';
import FormFieldNumber from 'components/common/FormFieldNumber';
import LastModified from 'components/common/LastModified';
import FormFieldDropDownGender from 'components/common/FormFieldDropDownGender';
import FormFieldDropDownLanguage from 'components/common/FormFieldDropDownLanguage';
import FormFieldDropDownCodeSet from 'components/common/FormFieldDropDownCodeSet';
import FormFieldDate from 'components/common/FormFieldDate';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import FormFieldCheckbox from 'components/common/FormFieldCheckbox';
import { Warning } from '@mui/icons-material';
import SsnHelper from 'utils/ssnHelper';
import PostalcodeService from 'services/postalcodeService';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import { useNavigate } from 'react-router-dom';
import ValidationService from 'services/validationService';
import AddressUtils from 'utils/addressHelper';
import PersonCloseRelatives from './PersonCloseRelatives';
import debounce from 'lodash/debounce';
import FormFieldReadOnly from 'components/common/FormFieldReadonly';
import PersonUtils from 'utils/personHelper';
import { DeleteDialogState, MemberPrerequisite, MembershipType } from 'types/common';
import DialogConfirmation from 'components/common/DialogConfirmation';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import config from 'config/config';
import constants from 'config/constants';
import FormFieldDropDownMunicipality from 'components/common/FormFieldDropDownMunicipality';
import FormFieldDropDownCountries from 'components/common/FormFieldDropDownCountries';
import FormFieldDropDownOrganization from 'components/common/FormFieldDropDownOrganization';
import MembershipsService from 'services/membershipsService';
import axios, { CancelTokenSource } from 'axios';
import CommunityTagList from './CommunityTagList';
import { AuthContext } from 'contexts/authContext';

const validateEmail = debounce(async (value) => ValidationService.isValidEmailAsync(value), 1000, {
    leading: true,
    trailing: true
});

const validateSsn = debounce(async (value) => ValidationService.isValidSsnAsync(value), 1000, {
    leading: true,
    trailing: true
});

const getValidationSchema = (T: LanguageTextFunc, postalCodes: PostOffice[], orginal: Person, contact?: boolean) => {
    const registerContactSchema = Yup.object().shape({
        organizationId: Yup.string().test('not-0-value', T('Common_Required'), (value) => value !== '0')
    });

    const personSchema = Yup.object().shape({
        firstName: Yup.string().required(T('Common_Required')),
        lastName: Yup.string().required(T('Common_Required')),
        email: Yup.string()
            .email(T('Error_InvalidEmail'))
            .test('email-exists', T('Error_EmailExist'), async (value) => {
                let validEmail = true;
                if (value && value != orginal.email) {
                    const result = await validateEmail(value);
                    validEmail = result !== undefined ? result : true;
                }
                return validEmail;
            })
            .nullable(),
        ssn: Yup.string()
            .required(T('Common_Required'))
            .test('ssn-validity', T('Error_InvalidSsn'), (value) => SsnHelper.isValidSsn(value))
            .test('ssn-exists', T('Error_SsnExist'), async (value) => {
                let validSsn = true;
                if (value && value?.length > 6 && value !== orginal.ssn) {
                    const result = await validateSsn(value);
                    validSsn = result !== undefined ? result : true;
                }
                return validSsn;
            })
            .nullable(),
        phoneHome: Yup.string()
            .test('phone-validity', T('Error_InvalidPhone'), (value) => /^[+0-9\-()\s]*$/.exec(value ?? '') !== null)
            .nullable(),
        address: Yup.object().shape({
            secret: Yup.boolean().nullable(),
            street: Yup.string().when('secret', { is: false, then: Yup.string().required(T('Common_Required')) }),
            zipCode: Yup.string().when('secret', {
                is: false,
                then: Yup.string()
                    .test('valid-zipCode', T('Error_InvalidZipCode'), (value) =>
                        AddressUtils.validateZipCode(value, postalCodes)
                    )
                    .required(T('Common_Required'))
            })
        })
    });

    if (contact) return personSchema.concat(registerContactSchema);

    return personSchema;
};

export interface PersonTabProps {
    person: Person & { organizationId?: number };
    setActiveTab?: (tab: number) => void;
    registerContact?: boolean;
}

interface PersonBasicTabProps extends PersonTabProps {
    refreshPerson: () => void;
}

const PersonTabBasic: FC<PersonBasicTabProps> = ({ person, refreshPerson, registerContact }: PersonBasicTabProps) => {
    const { T } = useContext(LanguageContext);
    const { isAdmin } = useContext(AuthContext);
    const [sending, setSending] = useState(false);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const [postalCodes, setPostalCodes] = useState<PostOffice[]>([]);
    const navigate = useNavigate();
    const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>();
    const [deleting, setDeleting] = useState(false);
    const [contactMembership, setContactMembership] = useState<Membership>();
    const cancelRef = useRef<CancelTokenSource | null>(null);

    useEffect(() => {
        PostalcodeService.getAllPostalCodesAsync().then((result) => {
            setPostalCodes(result);
        });
    }, []);

    const getMembership = useCallback(
        async (id?: number): Promise<void> => {
            if (!id || id == -1) return;

            try {
                cancelRef.current = axios.CancelToken.source();
                const memberships = await PersonsService.getPersonMembershipsAsync(id, cancelRef.current.token);
                if (cancelRef.current) {
                    setContactMembership(memberships.find((m) => m.type == MembershipType.RegisterContact));
                }
            } catch (error) {
                handleError(error, T('Error_PersonMembersipQueryFailed'));
            }
        },
        [handleError, T]
    );

    useEffect(() => {
        getMembership(person.id);

        return () => {
            cancelRef.current && cancelRef.current.cancel();
            cancelRef.current = null;
        };
    }, [getMembership, person]);

    const savePerson = async (values: Person & { organizationId?: number }, contact?: boolean): Promise<void> => {
        setSending(true);

        try {
            const result = await PersonsService.savePersonAsync(values);
            setSending(false);
            if (result.membershipEnded) {
                handleSuccess(T('Person_SaveSuccessWithDeceased'));
            } else {
                handleSuccess(T('Person_SaveSuccess'));
            }

            if (contact) {
                const memberships = await PersonsService.getPersonMembershipsAsync(person.id);

                let membership: Membership | undefined = memberships.find(
                    (m) => m.type == MembershipType.RegisterContact
                );

                if (!membership) {
                    membership = {
                        organizationId: values.organizationId ?? 0,
                        personId: result.id,
                        type: MembershipType.RegisterContact,
                        prerequisite: MemberPrerequisite.Transfer,
                        beginDate: new Date()
                    };
                }

                await MembershipsService.saveMembershipAsync(result.id, membership);
            }

            if (result.new) {
                // reload person page
                navigate(`/persons/${result.id}`);
            } else {
                refreshPerson();
            }
        } catch (error) {
            setSending(false);
            handleError(error, T('Person_SaveFailed'));
        }
    };

    const deletePerson = async () => {
        if (deleteDialogState?.id) {
            try {
                setDeleting(true);
                await PersonsService.deletePersonAsync(person.id);
                handleSuccess(T('Person_DeleteSuccess'));
                setDeleting(false);
                // redirect to main page
                navigate('/');
            } catch (error) {
                setDeleting(false);
                handleError(error, T('Person_DeleteFailed'));
            }
        }
    };

    return (
        <Formik
            initialValues={person}
            enableReinitialize
            onSubmit={(values, actions) => {
                actions.validateForm(values);
                savePerson(values, registerContact);
            }}
            validationSchema={getValidationSchema(T, postalCodes, person, registerContact)}
        >
            {({
                values,
                dirty,
                isValid,
                resetForm,
                getFieldHelpers
            }: FormikProps<Person & { organizationId?: number }>) => {
                const helpers = getFieldHelpers('address.city');
                const isUnderAge = SsnHelper.isUnderAge(values.ssn);
                const isDomesticAddress = AddressUtils.isDomesticZipCode(values.address?.zipCode);
                const isForeignAddress = AddressUtils.isForeignZipCode(values.address?.zipCode);
                let isCityError = false;
                let city = '';

                if (isDomesticAddress) {
                    city = AddressUtils.getCityByZipCode(
                        values.address?.zipCode,
                        postalCodes,
                        T('Error_InvalidZipCode')
                    );

                    if (values.address?.city !== city && city !== T('Error_InvalidZipCode')) {
                        helpers.setValue(city);
                    }
                }

                if (city === T('Error_InvalidZipCode') || (!isDomesticAddress && !isForeignAddress)) {
                    isCityError = true;
                }

                return (
                    <Form>
                        <Grid container alignItems="flex-start" spacing={5}>
                            <Grid container item xs={6} spacing={3}>
                                <Grid item xs={6}>
                                    <FormFieldText name="ssn" label={T('Person_FieldSsn')} autoFocus />
                                </Grid>
                                <Grid item container alignItems="center" xs={6}>
                                    {isUnderAge && (
                                        <>
                                            <Grid item style={{ marginRight: 8 }}>
                                                <Warning className="warning-icon" fontSize="small" />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">{T('Person_UnderAge')}</Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormFieldText name="lastName" label={T('Person_FieldLastName')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormFieldText name="nickName" label={T('Person_FieldNickName')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormFieldText name="firstName" label={T('Person_FieldFirstNames')} />
                                </Grid>
                                <Grid container item xs={12}>
                                    <FormFieldCheckbox
                                        name="addressUnknown"
                                        label={T('Person_FieldAddressUnknown')}
                                        color="warning"
                                    />
                                </Grid>
                                {!values.addressUnknown && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                name="address.street"
                                                label={T('Person_FieldAddressStreet')}
                                                endIcon={values.address?.secret ? Warning : undefined}
                                                endIconColor="warning"
                                                endIconTooltip={T('Person_PhoneSecretTooltip')}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormFieldText
                                                name="address.zipCode"
                                                label={T('Person_FieldAddressZipCode')}
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            {!isForeignAddress && (
                                                <FormFieldReadOnly
                                                    label={T('Person_FieldAddressCity')}
                                                    value={isCityError ? ' ' : values.address?.city}
                                                    error={isCityError}
                                                    helperText={isCityError ? T('Error_InvalidZipCode') : undefined}
                                                />
                                            )}
                                            {isForeignAddress && (
                                                <FormFieldText
                                                    name="address.city"
                                                    label={T('Person_FieldAddressCity')}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormFieldText
                                                name="address.country"
                                                label={T('Person_FieldAddressCountry')}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mt={-3}>
                                            <FormFieldCheckbox
                                                name="address.secret"
                                                label={T('Person_FieldAddressSecret')}
                                                color="warning"
                                                readonly={values.addressUnknown}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <FormFieldNumber
                                        name="phoneHome"
                                        label={T('Person_FieldPhoneHome')}
                                        endIcon={values.phoneHomeSecret ? Warning : undefined}
                                        endIconColor="warning"
                                        endIconTooltip={T('Person_PhoneSecretTooltip')}
                                    />
                                    <FormFieldCheckbox
                                        name="phoneHomeSecret"
                                        label={T('Person_FieldPhoneHomeSecret')}
                                        color="warning"
                                    />
                                </Grid>
                                {config.CUSTOMER === constants.Customers.Advk && (
                                    <Grid item xs={6}>
                                        <FormFieldNumber name="phoneWork" label={T('Person_FieldPhoneWork')} />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormFieldText name="email" label={T('Person_FieldEmail')} />
                                </Grid>
                                {config.CUSTOMER === constants.Customers.Advk && (
                                    <>
                                        <Grid item xs={6}>
                                            <FormFieldDropDownMunicipality
                                                name="municipalityResidenceId"
                                                label={T('Person_FieldMunicipalityResidence')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormFieldDate
                                                name="municipalityResidenceMovingDate"
                                                label={T('Person_FieldMunicipalityResidenceMovingDate')}
                                                disableFuture
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={8}>
                                    <FormFieldCheckbox name="headOfFamily" label={T('Person_FieldHeadOfFamily')} />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormFieldCheckbox
                                        name="emailCommunicationProhibited"
                                        label={T('Person_FieldEmailCommunicationProhibited')}
                                    />
                                </Grid>
                                <Grid item container xs={11} justifyContent="space-between">
                                    <Grid item>
                                        <LoadButton loading={sending} disabled={!dirty || !isValid} type="submit">
                                            {T('Common_Save')}
                                        </LoadButton>
                                        <Button
                                            disabled={!dirty && values.id !== -1}
                                            style={{ marginLeft: 16 }}
                                            onClick={() => {
                                                if (values.id !== -1) resetForm();
                                                else navigate('/persons');
                                            }}
                                            color="secondary"
                                        >
                                            {T('Common_Cancel')}
                                        </Button>
                                    </Grid>
                                    {person.id != -1 && isAdmin() && (
                                        <Grid item>
                                            <LoadButton
                                                loading={deleting}
                                                variant="outlined"
                                                color="error"
                                                onClick={() =>
                                                    setDeleteDialogState({
                                                        show: true,
                                                        message: T('Person_ConfirmDeleteMessage').replace(
                                                            '{0}',
                                                            PersonUtils.FullName(person)
                                                        ),
                                                        id: values.id ?? 0
                                                    })
                                                }
                                            >
                                                {T('Common_Delete')}
                                            </LoadButton>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Box margin={2} />
                                    {person.id !== -1 && (
                                        <LastModified by={values.lastModifiedBy} date={values.lastModifiedDate} />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} spacing={3}>
                                {registerContact === true ? (
                                    <Grid item xs={12}>
                                        <FormFieldDropDownOrganization
                                            name="organizationId"
                                            label={T('Membership_SubTitle_ContactOrganization')}
                                            placeholder={T('Membership_SelectOrganization')}
                                            showContacts
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} style={{ height: 64 }}></Grid>
                                )}
                                <Grid item xs={6}>
                                    <FormFieldDropDownLanguage
                                        name="language"
                                        label={T('Person_FieldContactLanguage')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormFieldDropDownCountries
                                        name="nationality"
                                        label={T('Person_FieldNationality')}
                                        placeholder={T('Person_SelectNationality')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormFieldDropDownCountries
                                        name="birthCountry"
                                        label={T('Person_FieldBirthCountry')}
                                        placeholder={T('Person_FieldBirthCountry')}
                                    />
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={6}>
                                    <FormFieldDropDownGender name="gender" label={T('Person_FieldGender')} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormFieldDropDownCodeSet
                                        name="maritalStatus"
                                        label={T('Person_FieldMaritalStatus')}
                                        codeSet="maritalStatus"
                                        placeholder={T('Person_SelectMaritalStatus')}
                                    />
                                </Grid>
                                {registerContact !== true && (
                                    <Grid item xs={12}>
                                        <FormFieldText name="profession" label={T('Person_FieldProfession')} />
                                    </Grid>
                                )}
                                <Grid item xs={6}>
                                    <FormFieldDate name="birthDate" label={T('Person_FieldBirthDate')} disableFuture />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormFieldDate
                                        name="deceasedDate"
                                        label={T('Person_FieldDeceasedDate')}
                                        disableFuture
                                    />
                                </Grid>
                                {config.CUSTOMER === constants.Customers.Advk && (
                                    <Grid item xs={12}>
                                        <FormFieldText
                                            name="municipalityBirth"
                                            label={T('Person_FieldMunicipalityBirth')}
                                        />
                                    </Grid>
                                )}
                                {registerContact !== true && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormFieldText name="maidenName" label={T('Person_FieldMaidenName')} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormFieldText
                                                name="previousNames"
                                                label={T('Person_FieldPreviousNames')}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <FormFieldTextArea name="info" label={T('Person_FieldInfo')} rows={5} />
                                </Grid>
                                {registerContact !== true && (
                                    <>
                                        <Grid item xs={12} style={{ height: 24 }}></Grid>
                                        {isUnderAge && (values.id ?? 0) > 0 && (
                                            <Grid item xs={12}>
                                                <PersonCloseRelatives
                                                    person={person}
                                                    textPrefix={'PersonGuardian'}
                                                    guardiansOnly
                                                />
                                            </Grid>
                                        )}
                                        {isUnderAge && (values.id ?? 0) <= 0 && (
                                            <Grid item xs={12}>
                                                <Typography color="textSecondary" variant="body2">
                                                    {T('Person_FieldGuardianHelpText')}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {registerContact === true && contactMembership && (
                                    <>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid container item xs={12} spacing={1} alignContent="flex-start">
                                            <CommunityTagList personId={person.id ?? 0} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <DialogConfirmation
                            title={T('Person_ConfirmDeleteTitle')}
                            message={deleteDialogState?.message}
                            show={deleteDialogState?.show}
                            onClose={() => setDeleteDialogState(undefined)}
                            onOk={deletePerson}
                            warning
                            okTitle={T('Common_Delete')}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default PersonTabBasic;
