import React, { FC, ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import { Print } from '@mui/icons-material';
import BreadcrumbBar from '../common/BreadcrumbBar';
import ViewTitle from '../common/ViewTitle';
import { LanguageContext } from 'contexts/languageContext';
import { AuthContext } from 'contexts/authContext';
import WaitScreen from 'components/common/WaitScreen';
import ContactService from 'services/contactService';
import ReportOrganizationChanges from './ReportOrganizationChanges';
import ReportMembersListWithAge from './ReportMembersListWithAge';
import ReportMembersList from './ReportMembersList';
import DataProtectionNotice from './DataProtectionNotice';
import { useParams } from 'react-router-dom';
import Helper from 'utils/typeHelper';
import ApartmentIcon from '@mui/icons-material/Apartment';
import axios, { CancelTokenSource } from 'axios';
import ReportMembersListExtract from './ReportMembersListExtract';
import ReportSmsUsageList from './ReportSmsUsageList';
import ReportAccordion from './ReportAccordion';
import { OrganizationType } from 'types/common';
import ReportOrganizationBoardMemberReport from './ReportOrganizationBoardMemberReport';
import ReportDvvMemberList from './ReportDvvMemberList';
import ReportDvvAddressList from './ReportDvvAddressList';
import constants from 'config/constants';

const ReportView: FC = () => {
    const { T } = useContext(LanguageContext);
    const [orgName, setOrgName] = useState<string>();
    const [orgType, setOrgType] = useState<number>(0);
    const { user, isAdmin } = useContext(AuthContext);
    const [expanded, setExpanded] = useState<string | false>(false);
    const cancelRef = useRef<CancelTokenSource | null>(null);
    const { organizationId } = useParams<'organizationId'>();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(
        organizationId
            ? parseInt(organizationId, 10)
            : isAdmin()
            ? constants.RegionalCommunity.Id
            : user?.homeOrganizationId ?? 0
    );

    const handleChange = (panel: string) => (_: ChangeEvent<unknown>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const getOrganizationInfo = async (): Promise<void> => {
            if (user) {
                cancelRef.current = axios.CancelToken.source();
                const org = await ContactService.getOrganizationAsync(selectedOrganizationId, cancelRef.current.token);
                if (cancelRef.current) {
                    setOrgName(org.name);
                    setOrgType(org.type);
                }
            }
        };

        getOrganizationInfo();
    }, [selectedOrganizationId, user]);

    if (!orgName || (!isAdmin() && !user?.homeOrganizationId) || !user) {
        return <WaitScreen />;
    }

    return (
        <Container>
            <Grid container>
                <BreadcrumbBar />
                <ViewTitle title={T('Common_Reports')} icon={Print} />
            </Grid>
            {user.organizations.length > 1 && (
                <Grid container style={{ marginBottom: 12 }}>
                    <Grid container item xs={6} spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{T('Report_SelectReportTargetChurch')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" variant="outlined" fullWidth>
                                <InputLabel variant="outlined">{T('Report_ReportTargetChurch')}</InputLabel>
                                <Select
                                    variant="outlined"
                                    onChange={(event: SelectChangeEvent<number>) => {
                                        setSelectedOrganizationId(event.target.value as number);
                                    }}
                                    value={selectedOrganizationId}
                                    label={T('Report_ReportTargetChurch')}
                                >
                                    {user.organizations
                                        .filter((o) => o.type !== OrganizationType.ContactInfo)
                                        .map((item, index) => (
                                            <MenuItem key={index} value={item.id}>
                                                {item.name}
                                                {Helper.IsYhdyskunta(item.type) && (
                                                    <ApartmentIcon
                                                        fontSize="small"
                                                        style={{ marginLeft: 8 }}
                                                        titleAccess={T('Report_ReportTargetWholeCommunity')}
                                                    />
                                                )}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <Box m={3} />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <DataProtectionNotice />
            <Grid item xs={12}>
                <ReportAccordion
                    title={T('Report_OrganizationChanges')}
                    name="panel1"
                    expanded={expanded}
                    onChange={handleChange}
                    helpText={T('Report_OrganizationChangesHelpText')}
                >
                    <ReportOrganizationChanges organizationId={selectedOrganizationId} organizationName={orgName} />
                </ReportAccordion>
                <ReportAccordion
                    title={T('Report_MembersList')}
                    name="panel2"
                    expanded={expanded}
                    onChange={handleChange}
                    helpText={T('Report_MembersListHelpText')}
                >
                    <ReportMembersList
                        organizationId={selectedOrganizationId}
                        organizationName={orgName}
                        organizationType={orgType}
                    />
                </ReportAccordion>
                <ReportAccordion
                    title={T('Report_MembersListWithAge')}
                    name="panel3"
                    expanded={expanded}
                    onChange={handleChange}
                    helpText={T('Report_MembersListWithAgeHelpText')}
                >
                    <ReportMembersListWithAge organizationId={selectedOrganizationId} organizationName={orgName} />
                </ReportAccordion>
                <ReportAccordion
                    title={T('Report_MembersListExtract')}
                    name="panel4"
                    expanded={expanded}
                    onChange={handleChange}
                    helpText={T('Report_MembersListExtractHelpText')}
                >
                    <ReportMembersListExtract organizationId={selectedOrganizationId} organizationName={orgName} />
                </ReportAccordion>
                {isAdmin() && (
                    <>
                        <ReportAccordion
                            title={T('Report_OrganizationBoardMemberReport')}
                            name="panel5"
                            expanded={expanded}
                            onChange={handleChange}
                            helpText={T('Report_OrganizationBoardMemberReportHelpText')}
                        >
                            <ReportOrganizationBoardMemberReport
                                organizationId={selectedOrganizationId}
                                organizationName={orgName}
                            />
                        </ReportAccordion>
                        <ReportAccordion
                            title={T('Report_SmsUsageList')}
                            name="panel6"
                            expanded={expanded}
                            onChange={handleChange}
                            helpText={T('Report_SmsUsageListHelpText')}
                        >
                            <ReportSmsUsageList />
                        </ReportAccordion>
                        <ReportAccordion
                            title={T('Report_DvvMemberReport')}
                            name="panel7"
                            expanded={expanded}
                            onChange={handleChange}
                            helpText={T('Report_DvvMemberReportHelpText')}
                        >
                            <ReportDvvMemberList
                                organizationId={selectedOrganizationId}
                                organizationName={T('Common_ReligionalCommunity')}
                            />
                        </ReportAccordion>
                        <ReportAccordion
                            title={T('Report_DvvAddressReport')}
                            name="panel8"
                            expanded={expanded}
                            onChange={handleChange}
                            helpText={T('Report_DvvAddressReportHelpText')}
                        >
                            <ReportDvvAddressList
                                organizationId={selectedOrganizationId}
                                organizationName={T('Common_ReligionalCommunity')}
                            />
                        </ReportAccordion>
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default ReportView;
